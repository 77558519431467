import { getDatabase, ref, child, get, onValue } from "firebase/database";
import { db } from "../firebase";

let unsubscribe = null;

export function getUsers(cb) {
  try {
    const dbRef = ref(getDatabase(), `profiles/`);
    unsubscribe = onValue(dbRef, (snapshot) => {
      if (snapshot.exists()) {
        const users = [];
        snapshot.forEach((doc) => {
          users.push(doc.val());
        });
        cb([...users]);
      } else {
        cb([]);
        console.log("No data available");
      }
    });
  } catch (error) {
    console.error(error);
    // cb(error)
  }
}

export const detachListener = {
  unsubscribe,
};
