import styles from "./index.module.scss";

const headingObj = [
  {
    label: "Total Plants in session",
    id: "plants_total",
    value: (v) => {
      return `${v}`;
    },
  },
  // {
  //   label: "Healthy Plants Identified",
  //   id: "plants_detected",
  //   value: (v) => {
  //     return `${v}`;
  //   },
  // },
  // {
  //   label: "Healthy Plants Correctly Identified",
  //   id: "plants_correctly_detected",
  //   value: (v) => {
  //     return `${v} Plants`;
  //   },
  // },
  {
    label: "Healthy Plants Incorrectly Identified",
    id: "plants_incorrectly_detected",
    value: (v) => {
      return `${v} Plants`;
    },
  },
  {
    label: "Total Virus in session",
    id: "virus_total",
    value: (v) => {
      return `${v} Virus`;
    },
  },
  {
    label: "Virus Identified",
    id: "virus_detected",
    value: (v) => {
      return `${v} Virus`;
    },
  },

  {
    label: "Virus Correctly Identified",
    id: "virus_correctly_detected",
    value: (v) => {
      return `${v} Virus`;
    },
  },
  {
    label: "Virus Incorrectly Identified",
    id: "plants_incorrectly_detected",
    value: (v) => {
      return `${v} Virus`;
    },
  },
  {
    label: "Virus Correctly Identified Names",
    id: "virus_correctly_detected_names",
    value: (v) => {
      return `${v}`;
    },
  },
  {
    label: "Virus Incorrectly detected Names",
    id: "virus_incorrectly_detected_names",
    value: (v) => {
      return `${v}`;
    },
  },
];

const UserDetailModal = ({ showUserDetails }) => {
  return (
    <div className={styles.container}>
      <h2>Details</h2>
      <div className={styles.data}>
        {headingObj.map((item, index) => {
          return (
            showUserDetails &&
            showUserDetails.data && (
              <div key={index} className={styles.itemWrapper}>
                <div className={styles.itemName}>{item.label}</div>
                <div className={styles.itemValue}>
                  {typeof showUserDetails.data[item.id] === "object" ? (
                    <div className={styles.array}>
                      {showUserDetails.data[item.id].join(", ")}
                    </div>
                  ) : (
                    item.value(
                      showUserDetails.data[item.id]
                        ? showUserDetails.data[item.id]
                        : 0
                    )
                  )}
                </div>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default UserDetailModal;
