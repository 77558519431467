import React from "react";
import styles from "./index.module.scss";
import { useSelector } from "react-redux";
import { stepsModules } from "../../steps";
import { convertTimestampToSeconds } from "../UserModules";
import { utils, writeFile } from "xlsx";
import moment from "moment";

const bestPerformers = [
  {
    name: "Aswin",
    moduleName: "Atonic uterus",
    score: 45,
    timeTaken: "06:12 mins",
  },
  {
    name: "Parul",
    moduleName: "Retained placenta",
    score: 34,
    timeTaken: "06:12 mins",
  },
  {
    name: "Poojya",
    moduleName: "Perineal tear",
    score: 44,
    timeTaken: "06:12 mins",
  },
  {
    name: "Aswin",
    moduleName: "Perineal tear",
    score: 44,
    timeTaken: "06:12 mins",
  },
  {
    name: "Aswin",
    moduleName: "Perineal tear",
    score: 44,
    timeTaken: "06:12 mins",
  },
  {
    name: "Aswin",
    moduleName: "Perineal tear",
    score: 44,
    timeTaken: "06:12 mins",
  },

  {
    name: "Ashfaque",
    moduleName: "Atonic uterus",
    score: 45,
    timeTaken: "06:12 mins",
  },
  {
    name: "Aswin",
    moduleName: "Atonic uterus",
    score: 45,
    timeTaken: "06:12 mins",
  },
  {
    name: "Poojya",
    moduleName: "Perineal tear",
    score: 44,
    timeTaken: "06:12 mins",
  },
  {
    name: "Ashfaque",
    moduleName: "Atonic uterus",
    score: 45,
    timeTaken: "06:12 mins",
  },
  {
    name: "Aswin",
    moduleName: "Atonic uterus",
    score: 45,
    timeTaken: "06:12 mins",
  },
];

const headingObj = [
  {
    label: "Top Performer",
    value: 200,
    id: "name",
  },
  {
    label: "Time Spent",
    value: 200,
    id: "spentTime",
  },
  {
    label: "Highest Score",
    value: 200,
    id: "score",
  },
];

const UserReport = () => {
  //   const analytics = useSelector((state) => state.details.analytics);
  const topPerformers = useSelector((state) => state.details.topPerformers);

  const handleDownloadAnalytics = () => {
    if (
      topPerformers.common &&
      Object.values(topPerformers.common).length > 0
    ) {
      let aoo = [];
      Object.values(topPerformers.common)
        .sort((a, b) => b.score - a.score)
        .map((step) => {
          let obj = {
            "User Id": step["userId"],
            sessionId: step["sessionId"],
            Name: step["name"],
            "Phone Number": step["phoneNumber"],
            City: step["city"],
            "Vendor Name": step["vendorName"],
            "Total Plants in session": step["plants_total"],
            // "Healthy Plants Identified": step["plants_detected"],
            // "Healthy Plants Correctly Identified":
            //   step["plants_correctly_detected"],
            // "Healthy Plants Incorrectly Identified":
            //   step["plants_incorrectly_detected"],
            "Total Virus in session": step["virus_total"],
            "Virus Identified": step["virus_detected"],
            "Virus Correctly Identified": step["virus_correctly_detected"],
            "Virus Incorrectly Identified": step["plants_incorrectly_detected"],
            "Start Time": moment
              .unix(step["startTimestamp"] / 1000)
              .format("dddd, MMMM Do YYYY, hh:mm a"),
            "End Time": moment
              .unix(step["endTimestamp"] / 1000)
              .format("dddd, MMMM Do YYYY, hh:mm a"),
            Completed: step["completed"] ? "Yes" : "No",
            Score: step["score"],
            "Minutes Spent": convertTimestampToSeconds(
              step?.endTimestamp - step?.startTimestamp
            ),
          };
          aoo.push(obj);
        });
      var workbook = utils.book_new();

      /* convert table "table1" to worksheet named "Sheet1" */
      var worksheet = utils.json_to_sheet(aoo);
      utils.book_append_sheet(workbook, worksheet, "Sheet1");
      writeFile(workbook, "topPerfomers.xlsx");
    }
  };

  const sortedData =
    (topPerformers.common &&
      Object.values(topPerformers.common).length > 0 &&
      Object.values(topPerformers.common).sort((a, b) => b.score - a.score)) ||
    [];

  return (
    <div className={styles.UserReportWrapper}>
      <div className={styles.headingObjWrapper}>
        {headingObj.map((item, index) => {
          return (
            <div key={index} className={styles.itemWrapper}>
              <div className={styles.itemName}>{item.label}</div>
              <div className={styles.itemValue}>
                {sortedData.length ? (
                  item.id === "name" ? (
                    <div className={styles.userContainer}>
                      <div className={styles.userBox}>
                        {sortedData[0][item.id]?.charAt(0).toUpperCase()}
                      </div>
                      <div>{sortedData[0][item.id]}</div>
                    </div>
                  ) : item.id === "spentTime" ? (
                    convertTimestampToSeconds(
                      sortedData[0]?.endTimestamp -
                        sortedData[0]?.startTimestamp
                    )
                  ) : (
                    `${parseInt(sortedData[0][item.id])}%`
                  )
                ) : (
                  0
                )}
              </div>
            </div>
          );
        })}
        <div onClick={handleDownloadAnalytics} className={styles.downloadBtn}>
          Download Analytics
        </div>
      </div>
      <div className={styles.perfomanceWrapperBox}>
        <div className={styles.perfomanceWrapper}>
          <div className={styles.heading}>Best performers</div>
          <div style={{ fontWeight: 700 }} className={styles.userWrapper}>
            <div className={styles.slNo}>Sl. No</div>
            <div className={styles.userName}>User Name</div>
            <div className={styles.moduleName}>Mobile Name</div>
            <div className={styles.moduleName}>Village/City</div>
            <div className={styles.score}>Score</div>
            <div className={styles.timeTaken}>Time taken</div>
            <div className={styles.moduleName}>Vendor Name</div>
          </div>
          {sortedData.length > 0 ? (
            sortedData.map((perfomer, index) => {
              return (
                <div key={index} className={styles.userWrapper}>
                  <div className={styles.slNo}>{index + 1}</div>
                  <div className={styles.userContainer}>
                    <div className={styles.userBox}>
                      {perfomer?.name?.charAt(0).toUpperCase()}
                    </div>
                    <div>{perfomer.name}</div>
                  </div>
                  <div className={styles.userName}>{perfomer?.phoneNumber}</div>
                  <div className={styles.userName}>{perfomer?.city}</div>
                  {/* {stepsModules[perfomer.id].name} */}
                  {/* <div className={styles.moduleName}>{perfomer.testId}</div> */}
                  <div className={styles.score}>
                    {parseInt(perfomer.score)}%
                  </div>
                  <div className={styles.timeTaken}>
                    {convertTimestampToSeconds(
                      perfomer?.endTimestamp - perfomer?.startTimestamp
                    ) || 0}
                  </div>
                  <div className={styles.userName}>{perfomer?.vendorName}</div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                textAlign: "center",
                fontWeight: 600,
                marginTop: "2rem",
              }}
            >
              No Data
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserReport;
