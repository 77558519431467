import { getDatabase, ref, child, get, onValue } from "firebase/database";

let unsubscribe = null;

export function getReports(cb) {
  try {
    const dbRef = ref(getDatabase(), `analytics/`);
    unsubscribe = onValue(dbRef, (snapshot) => {
      if (snapshot.exists()) {
        cb(snapshot.val());
      } else {
        cb([]);
        console.log("No data available");
      }
    });
  } catch (error) {
    console.error(error);
    cb(false);
  }
}

let topUsersListener = null;

export function getTopUsers(cb) {
  try {
    const dbRef = ref(getDatabase(), `leaderboard/global`);
    topUsersListener = onValue(dbRef, (snapshot) => {
      if (snapshot.exists()) {
        cb(snapshot.val());
      } else {
        cb([]);
        console.log("No data available");
      }
    });
  } catch (error) {
    console.error(error);
    cb(false);
  }
}

export const detachReportsListener = {
  unsubscribe,
  topUsersListener,
};
