import "./App.css";
import { Provider } from "react-redux";
import store from "./store";
import AuthContextProvider from "./context/authContext/authContextProvider";
import Router from "./components/Routes";

function App() {
  return (
    <Provider store={store}>
      <AuthContextProvider>
        <Router />
      </AuthContextProvider>
    </Provider>
  );
}

export default App;
