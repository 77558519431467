import React, { useContext, useState } from "react";
import styles from "./index.module.scss";
import UserDetails from "../UserDetails";
import { useSelector } from "react-redux";
import moment from "moment/moment";
// import { getUserSessions } from '../../managers/SessionManager'
// import { updateSessionSlice } from '../../features/session/sessionSlice'
import { AuthContext } from "../../context/authContext/authContextProvider";
import UserDetailModal from "../UserDetailModal";

export const converDate = (value) => {
  if (!value) {
    return;
  }
  var dateString = moment
    .unix(value / 1000)
    .format("dddd, MMMM Do YYYY, hh:mm a");
  return dateString;
};

export function sumOFHoursWorked(timeValue1, timeValue2) {
  var time1 = timeValue1.split(":");
  var time2 = timeValue2.split(":");

  let secondSum = Number(time1[1]) + Number(time2[1]);
  let minSum = Number(time1[0]) + Number(time2[0]);

  if (secondSum > 59) {
    secondSum = Math.abs(60 - secondSum);
    minSum += 1;
  }

  if (secondSum < 10) {
    secondSum = `0${secondSum}`;
  }

  if (minSum < 10) {
    minSum = `0${minSum}`;
  }

  return `${minSum}:${secondSum}`;
}

export const convertTimestampToSeconds = (ms) => {
  var milliseconds = parseInt((ms % 1000) / 100),
    seconds = parseInt((ms / 1000) % 60),
    minutes = parseInt((ms / (1000 * 60)) % 60),
    hours = parseInt((ms / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;
  if (minutes && seconds) {
    return minutes + ":" + seconds;
  } else {
    return "00:00";
  }
};

const userDetailsInputs = [
  {
    label: "Mobile No",
    id: "phoneNumber",
  },
  // {
  //   label: "Total Virus Detected",
  //   id: "totalVirusFound",
  // },
  {
    label: "Date",
    id: "dob",
  },
  {
    label: "Vendor Name",
    id: "vendorName",
  },
  {
    label: "Passcode",
    id: "otpcode",
  },
];

const UserModules = ({ handleShowUser }) => {
  const [showModal, setShowModal] = useState(false);
  const sessionDetails = useSelector((state) => state.sessions.sessionData);
  // const allSessions = useSelector((state) => state.sessions.allSessions);
  const [stepsList, setStepsList] = useState([]);
  const currentUser = useSelector((state) => state.details.currentUser);
  const { user } = useContext(AuthContext);
  const [showUserDetails, setShowUserDetails] = useState({
    enable: false,
    data: null,
  });

  //   const getTotalScore = () => {
  //     let _totalScore = 0;
  //     if (allSessions[currentUser.uid]) {
  //       Object.values(allSessions[currentUser.uid]).map((step) => {
  //         _totalScore += step.score;
  //       });
  //     }

  //     return _totalScore;
  //   };

  const handleUserDetailModal = (bool, data) => {
    setShowUserDetails({
      enable: bool,
      data: data,
    });
  };

  const handleShowUserDetails = (user) => {
    setStepsList(user.setps); //change to steps
    setShowModal((prev) => !prev);
  };
  return (
    <div className={styles.userModulesWrapper}>
      {user && user.role !== "user" && (
        <div onClick={handleShowUser} className={styles.backBtn}>
          Back
        </div>
      )}
      <div className={styles.userDetailsBox}>
        <div className={styles.leftBox}>
          <div className={styles.userNameWrapper}>
            <div className={styles.userInitials}>
              {currentUser.full_name?.split(" ")[0].charAt(0)}
            </div>
            <div className={styles.username}>{currentUser.full_name}</div>
          </div>
        </div>
        <div className={styles.rightBox}>
          {userDetailsInputs.map((input, i) => {
            return (
              <div key={i} className={styles.inputWrapper}>
                <div className={styles.label}>{input.label}</div>
                <div className={styles.value}>
                  {input.id == "totalVirusFound"
                    ? currentUser?.lastSession?.[input.id] || "0"
                    : currentUser[input.id]}
                  {input.id === "totalVirusFound" ? " Virus" : ""}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.table}>
        <div className={`${styles.userMap} ${styles.heading}`}>
          <div className={styles.index}>Sl. No</div>
          <div className={styles.time}>Start Time</div>
          <div className={styles.time}>End Time</div>
          <div className={styles.time}>Minutes Spent</div>
          <div className={styles.score}>Score</div>
          <div className={styles.score}>Result</div>
          <div className={styles.score}>Details</div>
        </div>
        {sessionDetails && Object.values(sessionDetails).length > 0 ? (
          Object.values(sessionDetails)
            .sort((a, b) => b.startTimestamp - a.startTimestamp)
            .map((user, index) => {
              return (
                <div
                  key={index}
                  // onClick={() => handleShowUserDetails(user)}
                  className={styles.userMap}
                >
                  <div className={styles.index}>{index + 1}</div>
                  <div className={styles.time}>
                    {converDate(user?.startTimestamp)}
                  </div>
                  <div className={styles.time}>
                    {converDate(user?.endTimestamp)}
                  </div>
                  <div>
                    {convertTimestampToSeconds(
                      user?.endTimestamp - user?.startTimestamp
                    )}
                  </div>
                  <div className={styles.score}>{parseInt(user?.score)}%</div>
                  <div className={styles.score}>
                    {user?.passFail ? "Pass" : "Fail"}
                  </div>

                  <div
                    className={styles.detailButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleUserDetailModal(true, user);
                    }}
                  >
                    View Details
                  </div>
                </div>
              );
            })
        ) : (
          <div className={styles.noSessions}>No Sessions</div>
        )}
      </div>
      {showModal && (
        <div className={styles.modalWrapper}>
          <div
            onClick={handleShowUserDetails}
            className={styles.modalOverlay}
          ></div>
          <div className={styles.modal}>
            <UserDetails stepsList={stepsList} />
          </div>
        </div>
      )}
      {showUserDetails.enable && (
        <div className={styles.modalWrapper}>
          <div
            onClick={() => handleUserDetailModal(false, null)}
            className={styles.modalOverlay}
          ></div>
          <div className={styles.modal}>
            <UserDetailModal
              showUserDetails={showUserDetails}
              handleUserDetailModal={handleUserDetailModal}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default UserModules;
