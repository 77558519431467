import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    usersList: null
};

const userSlice = createSlice({
    name: "usersList",
    initialState,
    reducers: {
        updateUsersList: (state, action) => {
            state.usersList = action.payload
        },
        updateUserPhone: (state, action) => {
            let newUserList = state.usersList.filter(user => user.uid !== action.payload.user.uid)
            newUserList.push(action.payload.user)
            state.usersList = [...newUserList]
        },
        addUser: (state, action) => {
            state.usersList.push(action.payload.user)
        }
    },
});

export const { updateUsersList, updateUserPhone, addUser } = userSlice.actions;

export default userSlice.reducer;