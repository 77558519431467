import React, { createContext, useState, useEffect } from "react";
import { auth } from "../../firebase";


export const AuthContext = createContext();

export default function AuthContextProvider(props) {
    const [user, setUser] = useState(null);

    useEffect(() => {
        auth.onAuthStateChanged(async (user) => {
            if (user) {
                let claims = (await user.getIdTokenResult(true)).claims
                let __user = {
                    displayName: user.displayName,
                    email: user.email,
                    role: claims.userType,
                    uid: user.email,
                    userid: user.email,
                    f_uid: user.uid,
                };

                localStorage.setItem("userAuth", JSON.stringify(__user));

                setUser({ ...__user });
            } else {
                console.log("user not logged in");
                localStorage.removeItem("userAuth");
                localStorage.clear();
                setUser(null);
            }
        });
    }, []);

    return (
        <AuthContext.Provider value={{ user }}>
            {props.children}
        </AuthContext.Provider>
    );
}
