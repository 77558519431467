import React, { useContext, useEffect, useRef, useState } from "react";
import { Navbar } from "../Navbar";
import styles from "./index.module.scss";
import Sidebar from "../Sidebar";
import Allusers from "../AllUsers";
import UserModules from "../UserModules";
import { useDispatch, useSelector } from "react-redux";
import {
  detachSessionsListener,
  getAllSessions,
} from "../../managers/SessionManager";
import {
  updateAllSessions,
  updateSessionSlice,
} from "../../features/session/sessionSlice";
import { updateUsersList } from "../../features/users/userSlice";
import { detachListener, getUsers } from "../../managers/usersManager";
import {
  updateCurrentUser,
  updateTopPerformers,
  updateUserAnalytics,
} from "../../features/details/detailSlice";
import { AuthContext } from "../../context/authContext/authContextProvider";
import UserReport from "../UserReport";
import {
  detachReportsListener,
  getReports,
  getTopUsers,
} from "../../managers/reportManager";

const Home = () => {
  const usersList = useSelector((state) => state.users.usersList);
  const allSessions = useSelector((state) => state.sessions.allSessions);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [allUserSearchInput, setAllUserSearchInput] = useState("");
  const userLogged = useRef(false);

  useEffect(() => {
    if (user && usersList) {
      console.log(user);
      if (user.role == "user") {
        usersList.map((_user) => {
          if (_user.uid == user.f_uid) {
            if (!userLogged.current) {
              userLogged.current = true;
              handleShowUser(_user);
            }
          }
        });
      } else {
        setLoading(false);
      }
    }
  }, [user, usersList]);

  const handleShowUser = (user) => {
    setShowUsers((prev) => !prev);
    dispatch(
      updateCurrentUser({
        ...user,
      })
    );
    setLoading(false);
    if (allSessions) {
      dispatch(updateSessionSlice({ ...allSessions[user.uid] }));
    } else {
      dispatch(updateSessionSlice([]));
    }
  };
  const dispatch = useDispatch();

  useEffect(() => {
    getUsers((res) => {
      if (res) {
        dispatch(updateUsersList([...res]));
      }
    });
    getAllSessions((res) => {
      if (res) {
        dispatch(updateAllSessions({ ...res }));
      }
    });
    // getReports((res) => {
    //   if (res) {
    //     dispatch(updateUserAnalytics({ ...res }));
    //   }
    // });
    getTopUsers((res) => {
      if (res) {
        dispatch(updateTopPerformers({ ...res }));
      }
    });

    return () => {
      if (detachReportsListener.unsubscribe) {
        detachReportsListener.unsubscribe();
      }
      if (detachSessionsListener.unsubscribe) {
        detachSessionsListener.unsubscribe();
      }
      if (detachListener.unsubscribe) {
        detachListener.unsubscribe();
      }
    };
  }, []);
  const [showUsers, setShowUsers] = useState(true);
  const [activeId, setActive] = useState("userActivityLog");
  return (
    <>
      <div className={styles.homeWrapper}>
        <Navbar
          searchInput={allUserSearchInput}
          setSearchInput={setAllUserSearchInput}
        />
        <Sidebar activeId={activeId} setActive={setActive} />
        {!loading ? (
          activeId == "userActivityLog" ? (
            <div className={styles.mainMenu}>
              {showUsers ? (
                <Allusers
                  searchInput={allUserSearchInput}
                  setSearchInput={setAllUserSearchInput}
                  handleShowUser={handleShowUser}
                />
              ) : (
                <UserModules handleShowUser={handleShowUser} />
              )}
            </div>
          ) : (
            <UserReport />
          )
        ) : (
          <span className={styles.loader}></span>
        )}
      </div>
    </>
  );
};

export default Home;
