export const stepsModules = {
    step1: {
        name: 'Injection of Uterotonic Drug',
        tasks: {
            task1: {
                name: 'Take Consent From Mother',
                order: 1,
            },
            task2: {
                name: "Disinfect mother's thigh with the swab",
                order: 2,
            },
            task3: {
                name: 'Discard the used swab ',
                order: 3,
            },
            task4: {
                name: 'Load Oxytocin into the syringe',
                order: 4,
            },
            task5: {
                name: 'Discard the used ampule ',
                order: 5,
            },
            task6: {
                name: 'Remove excess air from the syringe',
                order: 6,
            },
            task7: {
                name: 'Place left hand for support',
                order: 7,
            },
            task8: {
                name: 'Inject the drug',
                order: 8,
            },
            task9: {
                name: 'Discard the used syringe',
                order: 9,
            }
        }
    },
    step2: {
        name: 'Clamping and Cutting of Cord',
        tasks: {
            task1: {
                name: "Clamp the cord atleast 3 cms away from the baby’s umbilicus",
                order: 1,
            },
            task2: {
                name: 'Clamp the cord atleast 2 cms away from the first clamp',
                order: 2,
            },
            task3: {
                name: 'Place the sterile gauze under the cord',
                order: 3,
            },
            task4: {
                name: 'Cut the cord with scissors',
                order: 4,
            },
            task5: {
                name: 'Discard the gauze',
                order: 5,
            },
            task6: {
                name: 'Place the scissors in the kidney tray',
                order: 6,
            },
            task7: {
                name: 'Redirect clamped cord end towards the bin',
                order: 7,
            },
            task8: {
                name: 'Place the baby between the mother’s breasts',
                order: 8,
            }
        }
    },
    step3: {
        name: 'Controlled Cord Traction & Delivery of Placenta',
        tasks: {
            task1: {
                name: 'Correction steps'
            },
            task2: {
                name: 'Action at Birth'
            },
            task3: {
                name: 'Bag and Mask'
            },
            task4: {
                name: 'Suctioning'
            }
        }
    },
    step4: {
        name: 'Visual Estimation of Blood Loss',
        tasks: {
            task1: {
                name: 'Assessment of blood loss',
                order: 1
            },
            task2: {
                name: 'Call for help initiated',
                order: 2
            },
            task3: {
                name: 'General Management for potential PPH',
                order: 3
            }
        }
    },
    step5: {
        name: 'General Managment for potential PPH',
        tasks: {
            task1: {
                name: 'Correction steps'
            },
            task2: {
                name: 'Action at Birth'
            },
            task3: {
                name: 'Bag and Mask'
            },
            task4: {
                name: 'Suctioning'
            }
        }
    },
    step6: {
        name: 'Quick Examination of Placenta',
        tasks: {
            task1: {
                name: 'Examine Incomplete placenta',
                order: 1,
            },
            task2: {
                name: 'Management protocol to prevent PPH Initiation',
                order: 2,
            },
            task3: {
                name: 'Management protocol to prevent PPH Check List Completed',
                order: 3
            }
        }
    },
    step7: {
        name: 'Post Partum Vigilance',
        tasks: {
            task1: {
                name: 'Take consent of the mother',
                order: 1,
            },
            task2: {
                name: 'Assure the mother',
                order: 2,
            },
            task3: {
                name: 'Assess if the uterus is contracting',
                order: 3,
            },
            task4: {
                name: 'Massage the uterine fundus in a circular motion',
                order: 4,
            },
            task5: {
                name: 'Management protocol to prevent PPH',
                order: 5,
            }
        }
    },
    step8: {
        name: 'Perineal Examincation',
        tasks: {
            task1: {
                name: 'Take consent of the mother',
                order: 1,
            },
            task2: {
                name: 'Assure the mother',
                order: 2,
            },
            task3: {
                name: 'Position the torch light to fall on the perineum',
                order: 3,
            },
            task4: {
                name: 'Examine Tear',
                order: 4,
            },
            task5: {
                name: 'Assess the degree of tear',
                order: 5,
            }
        }
    },

    step9: { //IncompletePlacenta
        name: 'Managment Protocol to prevent PPH',
        tasks: {
            task1: {
                name: 'Correction steps'
            },
            task2: {
                name: 'Action at Birth'
            },
            task3: {
                name: 'Bag and Mask'
            },
            task4: {
                name: 'Suctioning'
            }
        }
    },
    step12: { //AtonicUterus
        name: 'Managment Protocol to prevent PPH',
        tasks: {
            task1: {
                name: 'Correction steps'
            },
            task2: {
                name: 'Action at Birth'
            },
            task3: {
                name: 'Bag and Mask'
            },
            task4: {
                name: 'Suctioning'
            }
        }
    },
    step13: {//PerinealTear
        name: 'Managment Protocol to prevent PPH',
        tasks: {
            task1: {
                name: 'Correction steps'
            },
            task2: {
                name: 'Action at Birth'
            },
            task3: {
                name: 'Bag and Mask'
            },
            task4: {
                name: 'Suctioning'
            }
        }
    },

    step10: {
        name: 'Immediate Perineal Care',
        tasks: {
            task1: {
                name: 'Dip the swab in warm water',
                order: 1,
            },
            task2: {
                name: 'Clean the vulva and perineum',
                order: 2,
            },
            task3: {
                name: 'Dispose the swab',
                order: 3,
            },
            task4: {
                name: 'Pat dry the vulva and perineum with a clean dry cloth',
                order: 4,
            },
            task5: {
                name: 'Discard the cloth',
                order: 5,
            },
            task6: {
                name: 'Place a pad on the perineum',
                order: 6,
            },
            task7: {
                name: 'Management protocol to prevent PPH',
                order: 7,
            }
        }
    },
    step11: {
        name: 'Detailed Exmination',
        tasks: {
            task1: {
                name: 'Observe the maternal surface',
                order: 1,
            },
            task2: {
                name: 'Observe the foetal surface',
                order: 2,
            },
            task3: {
                name: 'Observe the membranes',
                order: 3,
            },
            task4: {
                name: 'Observe the umbilical cord',
                order: 4,
            },
        }
    }
};
