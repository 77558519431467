// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBWDhskzCgEZkTnuBeOXk6vPNFL3Ui31XE",
  authDomain: "pepsico-vr.firebaseapp.com",
  databaseURL:
    "https://pepsico-vr-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "pepsico-vr",
  storageBucket: "pepsico-vr.appspot.com",
  messagingSenderId: "1071383651482",
  appId: "1:1071383651482:web:427a77a68f01c9fecaab23",
  measurementId: "G-HEV0DMGHZP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const functions = getFunctions(app, "asia-south1");
export const auth = getAuth(app);

export const db = getFirestore(app);
