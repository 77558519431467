import React, { useReducer, useState } from "react";
import styles from "./index.module.scss";
import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import tick from "../../assets/images/tick.png";
import { InstituteNames } from "../../constants/applicationConstants";

const inputs = [
  {
    name: "Full Name",
    id: "full_name",
    placeholder: "Enter Name",
    type: "text",
  },
  {
    name: "Phone",
    id: "phoneNumber",
    placeholder: "Enter phone number",
    type: "number",
  },
  {
    name: "Father's Name",
    id: "fathers_name",
    placeholder: "Enter Father's Name",
    type: "text",
  },
  {
    name: "Village/City",
    id: "village_city",
    placeholder: "Enter Village",
    type: "text",
  },
  {
    name: "Birth Date",
    id: "dob",
    placeholder: "",
    type: "date",
  },
  {
    name: "State",
    id: "state",
    placeholder: "Enter State",
    type: "text",
  },

  {
    name: "Qualification",
    id: "qualification",
    placeholder: "Enter qualification",
    type: "select",
    options: [
      {
        name: "Less than Metric",
      },
      {
        name: "Metric",
      },
      {
        name: "Higher Education",
      },
      {
        name: "Graduate",
      },
    ],
  },

  {
    name: "Roguing Experience (Years)",
    id: "experience",
    placeholder: "Select Experience",
    type: "select",
    options: new Array(30).fill(1).map((arr, index) => {
      return {
        name: index,
      };
    }),
  },
  {
    name: "Vendor Name",
    id: "vendorName",
    placeholder: "Enter vendor name",
    type: "text",
  },
];

const defaultErrors = {
  full_name: "",
  phoneNumber: "",
  fathers_name: "",
  village_city: "",
  dob: "",
  state: "",
  qualification: "",
  experience: "",
  vendorName: "",
};

const initialState = {
  full_name: "",
  phoneNumber: "",
  fathers_name: "",
  village_city: "",
  dob: "",
  state: "",
  qualification: "",
  experience: "",
  vendorName: "",
  errors: {
    ...defaultErrors,
  },
};

const actionType = {
  handleInputChange: "handleInputChange",
  handleError: "handleError",
  handleLoading: "handleLoading",
  handleInitialState: "handleInitialState",
};

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionType.handleInputChange:
    case actionType.handleError:
    case actionType.handleLoading:
      return { ...state, ...payload };
    case actionType.handleInitialState:
      return { ...JSON.parse(JSON.stringify(initialState)) };
    default:
      return { ...state };
  }
};

const UserForm = ({ handleUserForm }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const validateForm = () => {
    let errors = state.errors;
    errors.full_name =
      state.full_name.trim().length > 0 ? "" : "Please enter valid Full Name";
    errors.phoneNumber =
      state.phoneNumber.trim().length > 0 &&
      state.phoneNumber.trim().length == 10
        ? ""
        : "Please enter valid Phone Number";
    errors.fathers_name =
      state.fathers_name.trim().length > 0
        ? ""
        : "Please enter valid Father's Name";
    errors.village_city =
      state.village_city.trim().length > 0
        ? ""
        : "Please enter valid village/city";
    errors.dob =
      state.dob.trim().length > 0 ? "" : "Please select your date of Birth";
    errors.state = state.state.trim().length > 0 ? "" : "Please enter state";

    errors.qualification =
      state.qualification.trim().length > 0
        ? ""
        : "Please enter valid qualification";
    errors.experience =
      state.experience.trim().length > 0 ? "" : "Please select your Experience";
    errors.vendorName =
      state.vendorName.trim().length > 0 ? "" : "Please enter vendor name";

    dispatch({
      type: actionType.handleError,
      payload: { errors: { ...errors } },
    });
  };

  const isValidForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };

  const handleInputChange = (e) => {
    dispatch({
      type: actionType.handleInputChange,
      payload: { [e.target.name]: e.target.value },
    });
  };
  const [showResponse, setShowResponse] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmitData = () => {
    setLoading(true);
    validateForm();
    if (!isValidForm(state.errors)) {
      dispatch({
        type: actionType.handleLoading,
        payload: { isLoading: false },
      });
      setLoading(false);
      return;
    }
    let _state = { ...state };
    delete _state.errors;
    _state.phoneNumber = "+91" + _state.phoneNumber;

    const obj = {
      userType: "user",
      ..._state,
    };

    let cloudRef = httpsCallable(functions, "createUserFromDashboard");

    cloudRef(obj).then((res) => {
      if (res) {
        setLoading(false);
        // reduxDispatch(addUser({ user: { ...obj, code: res.data.usercode } }))
        setShowResponse(true);
      } else {
        setLoading(false);
        setShowResponse(false);
      }
    });
  };

  const handleNextUser = () => {
    setShowResponse(false);
    dispatch({
      type: actionType.handleInitialState,
      payload: {},
    });
  };

  return (
    <>
      <div className={styles.formWrapper}>
        <div className={styles.header}>User registration</div>
        <div className={styles.inputsWrapper}>
          {inputs.map((input) => {
            return (
              <div
                className={`${styles.inputBox} ${
                  input.type == "textArea" && styles.textArea
                }`}
              >
                <div className={styles.label}>
                  {input.name}
                  <div className={styles.errors}>{state.errors[input.id]}</div>
                </div>
                {input.type == "textArea" ? (
                  <textarea
                    name={input.id}
                    placeholder={input.placeholder}
                    value={state[input.id]}
                    onChange={handleInputChange}
                    type={input.type}
                    className={styles[input.type]}
                  />
                ) : input.type == "select" ? (
                  <select
                    name={input.id}
                    placeholder={input.placeholder}
                    onChange={handleInputChange}
                    className={styles[input.type]}
                  >
                    <option hidden disabled selected value></option>
                    {input.options.map((opt) => {
                      return (
                        <option
                          value={input.id == "institute" ? opt.id : opt.name}
                          className={styles.options}
                        >
                          {input.id == "institute"
                            ? InstituteNames[opt.id]
                            : opt.name}
                        </option>
                      );
                    })}
                  </select>
                ) : input.id == "phoneNumber" ? (
                  <div className={styles.inputWrapperPhone}>
                    <div className={styles.countryCode}>+91</div>
                    <input
                      name={input.id}
                      placeholder={input.placeholder}
                      style={{ width: "100%", paddingLeft: "3.25rem" }}
                      value={state[input.id]}
                      onChange={handleInputChange}
                      type={input.type}
                      className={styles[input.type]}
                    />
                  </div>
                ) : (
                  <input
                    name={input.id}
                    placeholder={input.placeholder}
                    value={state[input.id]}
                    onChange={handleInputChange}
                    type={input.type}
                    className={styles[input.type]}
                  />
                )}
              </div>
            );
          })}
        </div>
        <div
          onClick={handleSubmitData}
          disabled={loading}
          className={styles.submit}
        >
          {loading ? <span className={styles.loader}></span> : "Submit"}
        </div>
      </div>
      {showResponse && (
        <div className={styles.modalWrapperInset}>
          <div className={styles.modal}>
            <img src={tick} className={styles.img} />
            <div>User registration successful </div>
            {/* <div>{`User passcode is : ${accessCode}`}</div> */}
            <div className={styles.btnWrapper}>
              <button
                onClick={handleUserForm}
                style={{ backgroundColor: "#505050" }}
              >
                Back
              </button>
              <button onClick={handleNextUser}>Add Another</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserForm;
