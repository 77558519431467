import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import nextArrow from "../../assets/images/nextArrow.png";
import editBtn from "../../assets/images/edit.png";
import UserForm from "../UserForm";
import { useDispatch, useSelector } from "react-redux";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";
import { updateUserPhone } from "../../features/users/userSlice";
import { convertTimestampToSeconds, sumOFHoursWorked } from "../UserModules";
import UserDetailModal from "../UserDetailModal";
import Fuse from "fuse.js";
import { utils, writeFile } from "xlsx";
import moment from "moment";

const searchOptions = {
  includeScore: true,
  keys: ["full_name"],
};

const Allusers = ({ searchInput, handleShowUser }) => {
  const [showUserForm, setShowUserForm] = useState(false);
  const [showUserDetails, setShowUserDetails] = useState({
    enable: false,
    data: null,
  });
  const [dataVisible, setDataVisible] = useState([]);
  const usersList = useSelector((state) => state.users.usersList);
  const allSessions = useSelector((state) => state.sessions.allSessions);
  const [loading, setLoading] = useState(false);

  const [editable, setEditable] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const dispatch = useDispatch();
  const fuseRef = useRef(null);

  useEffect(() => {
    if (usersList && usersList.length) {
      fuseRef.current = new Fuse(usersList, searchOptions);
      setDataVisible(usersList);
    }
  }, [usersList]);

  useEffect(() => {
    if (searchInput && searchInput.length > 0 && fuseRef.current) {
      const result = fuseRef.current.search(`${searchInput}`);

      setDataVisible(result.map((rs) => rs.item));
    } else {
      setDataVisible(usersList);
    }
  }, [searchInput]);

  const handleUserForm = () => {
    setShowUserForm((prev) => !prev);
  };

  const handleUserDetailModal = (bool, data) => {
    setShowUserDetails({
      enable: bool,
      data: data,
    });
  };

  const handlePhoneUpdate = (e) => {
    if (e.target.value.length < 3) {
      return;
    }
    setPhoneNumber(e.target.value);
  };

  const handleUpdatePhoneNumber = (user) => {
    if (phoneNumber.length !== 13) {
      return;
    }
    setLoading(true);
    let obj = {
      phoneNumber,
      userId: user.uid,
    };
    let cloudRef = httpsCallable(functions, "updateUserPassword");
    cloudRef(obj).then((res) => {
      if (res) {
        let _user = { ...user };
        _user.phoneNumber = phoneNumber;
        dispatch(updateUserPhone({ user: _user }));
        handleEditable(null, null);
        setLoading(false);
      } else {
        setLoading(false);
        console.log("error");
      }
    });
  };

  const getTotalTimeTaken = (user) => {
    let time1 = null;
    let time2 = null;
    let totalTime = null;
    if (allSessions && allSessions[user.uid]) {
      Object.values(allSessions[user.uid]).map((_session) => {
        if (_session.endTimestamp) {
          if (time1 == null) {
            time1 = convertTimestampToSeconds(
              _session?.endTimestamp - _session?.startTimestamp
            );
          } else {
            time2 = convertTimestampToSeconds(
              _session?.endTimestamp - _session?.startTimestamp
            );
          }
          if (time1 && time2) {
            totalTime = sumOFHoursWorked(time1, time2);
            time1 = totalTime;
            time2 = null;
          }
        }
      });
    }

    if (totalTime == null) {
      if (time1) {
        return time1;
      } else {
        return "00:00";
      }
    } else {
      return totalTime;
    }
  };

  const handleEditable = (user, ind) => {
    setEditable(ind);
    setPhoneNumber(user?.phoneNumber);
  };

  const handleDownloadAnalytics = () => {
    if (usersList && usersList.length > 0) {
      let aoo = [];
      usersList.forEach((step) => {
        if (step.lastSession && step.lastSession.completed) {
          let obj = {
            "User Id": step.lastSession["userId"],
            sessionId: step.lastSession["sessionId"],
            Name: step.lastSession["name"],
            "Phone Number": step.lastSession["phoneNumber"],
            City: step.lastSession["city"],
            "Vendor Name": step.lastSession["vendorName"],
            "Total Plants in session": step.lastSession["plants_total"],
            // "Healthy Plants Identified": step.lastSession["plants_detected"],
            // "Healthy Plants Correctly Identified":
            //   step.lastSession["plants_correctly_detected"],
            // "Healthy Plants Incorrectly Identified":
            //   step.lastSession["plants_incorrectly_detected"],
            "Total Virus in session": step.lastSession["virus_total"],
            "Virus Identified": step.lastSession["virus_detected"],
            "Virus Correctly Identified":
              step.lastSession["virus_correctly_detected"],
            "Virus Incorrectly Identified":
              step.lastSession["plants_incorrectly_detected"],
            "Start Time": moment
              .unix(step.lastSession["startTimestamp"] / 1000)
              .format("dddd, MMMM Do YYYY, hh:mm a"),
            "End Time": moment
              .unix(step.lastSession["endTimestamp"] / 1000)
              .format("dddd, MMMM Do YYYY, hh:mm a"),
            Completed: step.lastSession["completed"] ? "Yes" : "No",
            Score: step.lastSession["score"] + "%",
            Result: step.lastSession["passFail"] ? "Pass" : "Fail",
            "Minutes Spent": convertTimestampToSeconds(
              step.lastSession?.endTimestamp - step.lastSession?.startTimestamp
            ),
          };
          aoo.push(obj);
        }
      });
      var workbook = utils.book_new();

      /* convert table "table1" to worksheet named "Sheet1" */
      var worksheet = utils.json_to_sheet(aoo);
      utils.book_append_sheet(workbook, worksheet, "Sheet1");
      writeFile(workbook, "userLastSession.xlsx");
    }
  };

  return (
    <div className={styles.allusersWrapper}>
      <div className={styles.header}>
        Latest Report
        <div className={styles.btnWrapper}>
          <div onClick={handleDownloadAnalytics} className={styles.addUser}>
            Download Analytics
          </div>
          <div onClick={handleUserForm} className={styles.addUser}>
            Add User
          </div>
        </div>
      </div>
      <div className={styles.usersWrappers}>
        <div className={styles.lineWrapperHeading}>
          <div>User</div>
          <div>Start Time</div>
          <div>End Time</div>
          <div>Minutes Spent</div>
          <div>Score</div>
          <div>Result</div>
          <div>Details</div>
        </div>
        {dataVisible && dataVisible.length > 0 ? (
          dataVisible.map((user, index) => {
            const lastSession = user.lastSession || null;
            let startTime = "";
            let endTime = "";
            let timeSpent = "";
            let score = 0;
            let passFail = "Fail";
            if (lastSession) {
              startTime = lastSession.startTimestamp;
              endTime = lastSession.endTimestamp;
              score = lastSession.score;
              passFail = lastSession.passFail ? "Pass" : "Fail";
              timeSpent = convertTimestampToSeconds(endTime - startTime) || "-";
            }
            return (
              <div key={index} style={{ position: "relative" }}>
                {/* <div className={styles.editBtn}>
                  {editable == index && phoneNumber ? (
                    <div
                      onClick={() => handleUpdatePhoneNumber(user)}
                      className={styles.doneBtn}
                    >
                      {loading ? (
                        <span className={styles.loader}></span>
                      ) : (
                        "done"
                      )}
                    </div>
                  ) : (
                    <img
                      onClick={() => handleEditable(user, index)}
                      src={editBtn}
                    />
                  )}
                </div> */}
                <div
                  style={{
                    background:
                      editable == index && phoneNumber ? "#fff" : "none",
                  }}
                  className={styles.lineWrapper}
                  onClick={() => handleShowUser(user)}
                >
                  <div className={styles.userContainer}>
                    <div className={styles.userBox}>
                      {user?.full_name?.charAt(0).toUpperCase()}
                    </div>
                    <div>{user.full_name}</div>
                  </div>
                  <div>
                    {startTime ? new Date(startTime).toGMTString() : "-"}
                  </div>
                  <div>{endTime ? new Date(endTime).toGMTString() : "-"}</div>
                  <div>{timeSpent}</div>
                  <div>{parseInt(score)}%</div>
                  <div>{passFail}</div>
                  {/* <div>{getTotalTimeTaken(user)}</div> */}
                  <div
                    className={styles.detailButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleUserDetailModal(true, lastSession);
                    }}
                  >
                    View Details
                  </div>

                  {/* {editable == index && phoneNumber ? (
                    <input
                      value={phoneNumber}
                      type="text"
                      onChange={handlePhoneUpdate}
                      className={styles.phoneInput}
                    />
                  ) : (
                    <div>{user.phoneNumber}</div>
                  )} */}
                </div>
              </div>
            );
          })
        ) : (
          <div className={styles.noUsers}>No Users</div>
        )}
      </div>
      {showUserDetails.enable && (
        <div className={styles.modalWrapper}>
          <div
            onClick={() => handleUserDetailModal(false, null)}
            className={styles.modalOverlay}
          ></div>
          <div className={styles.modal}>
            <UserDetailModal
              showUserDetails={showUserDetails}
              handleUserDetailModal={handleUserDetailModal}
            />
          </div>
        </div>
      )}
      {showUserForm && (
        <div className={styles.modalWrapper}>
          <div onClick={handleUserForm} className={styles.modalOverlay}></div>
          <div className={styles.modal}>
            <UserForm handleUserForm={handleUserForm} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Allusers;
