import { createSlice } from "@reduxjs/toolkit";
import { applicationIdType } from "../../constants/applicationConstants";

const initialState = {
    currentUser: null,
    applicationId: applicationIdType.punjab,
    userType: "",
    analytics: null,
    topPerformers: {}
};

const detailSlice = createSlice({
    name: "details",
    initialState,
    reducers: {
        updateCurrentUser: (state, action) => {
            state.currentUser = action.payload
        },
        updateApplicationId: (state, action) => {
            state.applicationId = action.payload
        },
        updateUserType: (state, action) => {
            state.userType = action.payload
        },
        updateUserAnalytics: (state, action) => {
            state.analytics = action.payload
        },
        updateTopPerformers: (state, action) => {
            state.topPerformers = action.payload
        }
    },
});

export const { updateCurrentUser, updateApplicationId, updateUserType, updateUserAnalytics, updateTopPerformers } = detailSlice.actions;

export default detailSlice.reducer;