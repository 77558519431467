import React, { useContext, useEffect, useState } from 'react'
import styles from "./index.module.scss"
import nextArrow from "../../assets/images/nextArrow.png"
import { stepsModules } from '../../steps'
import { AuthContext } from '../../context/authContext/authContextProvider'
import { useSelector } from 'react-redux'
import downloadPng from "../../assets/images/download.png"
import { converDate } from '../UserModules'
import moment from 'moment'
import { utils, writeFile } from 'xlsx'


const extraDetailsKeys = [
    "Task Order",
    "Activity Name",
    "Completed",
    "Correct Sequence",
    "Score"
]


const getEmojiByPercentage = (scorePercentage) => {
    if (scorePercentage.toFixed() < 25 && scorePercentage.toFixed() > 0) {
        return "🙂"
    } else if (scorePercentage.toFixed() >= 25 && scorePercentage.toFixed() < 50) {
        return "😄"
    } else if (scorePercentage.toFixed() >= 50 && scorePercentage.toFixed() < 75) {
        return "😁"
    } else if (scorePercentage.toFixed() >= 75 && scorePercentage.toFixed() <= 100) {
        return "😍"
    } else if (scorePercentage.toFixed() <= 0) {
        return "☹️"
    }
}

const UserDetails = ({ stepsList }) => {

    const [showExtraDetails, setShowExtraDetails] = useState(null)
    const currentUser = useSelector(state => state.details.currentUser)

    const handleShowExtraDetails = (ind) => {
        if (showExtraDetails == ind) {
            setShowExtraDetails(null)
        } else {
            setShowExtraDetails(ind)
        }
    }

    const handleDownload = () => {
        let aoo = []
        stepsList.map(step => {
            let _filteredArr = step.task && step.task.slice().filter(_item => _item.inorderIndex == -1)
            let _newArr = step.task.slice().filter(_step => _step.inorderIndex !== -1).sort((a, b) => b.inorderIndex - a.inorderIndex)
            let taskArr = [..._newArr, ..._filteredArr]
            let obj = {}
            obj["Module Name"] = stepsModules[step?.id].name
            obj["Step Name"] = ""
            obj.score = step?.totalPoints
            obj["Minutes Spent"] = moment.utc(step?.timespent * 1000).format('mm:ss')
            aoo.push(obj)
            taskArr.map((details, index) => {
                let newObj = {
                    ["Module Name"]: stepsModules[step?.id].name,
                    ["Step Name"]: stepsModules[step?.id].tasks[details.taskid].name,
                    score: details.score,
                    "Minutes Spent": ""
                }
                aoo.push(newObj)
            })

        })
        var workbook = utils.book_new();

        /* convert table "table1" to worksheet named "Sheet1" */
        var worksheet = utils.json_to_sheet(aoo);
        utils.book_append_sheet(workbook, worksheet, "Sheet1");
        writeFile(workbook, "userDetails.xlsx");
    }
    return (
        <div className={styles.userDetailsWrapper}>
            <div className={styles.list}>
                <div className={styles.subHeader}>
                    <div className={styles.userName}>{currentUser.first_name}</div>
                    <div className={styles.labelWrapper}>
                        <div onClick={handleDownload} className={styles.downloadBtn}>Download Reports
                            <img src={downloadPng} className={styles.downloadPng} />
                        </div>
                        <div className={styles.label}>
                            <div>Total score</div>
                            <div>Minutes Spent</div>
                        </div>
                    </div>
                </div>
                {stepsList ? stepsList.map((item, index) => {
                    let _filteredArr = item.task && item.task.slice().filter(_item => _item.inorderIndex == -1)
                    let _newArr = item.task.slice().filter(_item => _item.inorderIndex !== -1).sort((a, b) => a.inorderIndex - b.inorderIndex)
                    return <><div onClick={() => handleShowExtraDetails(index)} className={styles.itemWrapper}>
                        <div className={styles.itemName}>
                            <div className={styles.dropdownIcon}>
                                <img src={nextArrow} />
                            </div>
                            {stepsModules[item?.id].name}
                        </div>
                        <div className={styles.label}>
                            <div style={{ color: item.totalPoints < 0 ? "#FF0000" : "#707070", display: "flex", justifyContent: 'center', alignItems: "center" }}>{item?.totalPoints}/{5 * item.task.length}
                                {getEmojiByPercentage(item?.totalPoints / (item.task.length * 10) * 100)}
                            </div>
                            <div>{moment.utc(item.timespent * 1000).format('mm:ss')}</div>
                        </div>
                    </div>
                        {showExtraDetails == index && <div className={styles.extraDetailWrapper}><table className={styles.extraDetails}>
                            <tr style={{ fontWeight: 700 }}>
                                {extraDetailsKeys.map(key => {
                                    return <td>{key}</td>
                                })}
                            </tr>
                            {
                                [..._newArr, ..._filteredArr].map((details, index) => {
                                    return <tr>
                                        <td>{stepsModules[item.id].tasks[details.taskid]?.order}</td>
                                        <td>{stepsModules[item.id].tasks[details.taskid]?.name}</td>
                                        <td style={{ fontWeight: 600, color: details.completed ? "#21C507" : "#FF0000" }} >{details.completed ? "Yes" : "No"}</td>
                                        <td style={{ fontWeight: 600, color: details.inorder ? "#21C507" : "#FF0000" }} >{details.inorder ? "Yes" : "No"}</td>
                                        <td>{details.score}
                                        </td>
                                    </tr>
                                })

                            }

                        </table></div>}
                    </>
                }) : <div className={styles.noTask}>No Tasks Added</div>}
            </div>
        </div>
    )
}

export default UserDetails